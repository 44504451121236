import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { getBranch, setBranch } from 'services/storages/userStorage';

export const useGetMe = () => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_ME],
    staleTime: 1000 * 60,
    queryFn: async () => {
      const me = await API.user.getMe();
      if (!getBranch()) {
        setBranch(me.branch_ids[0]);
      }
      return me;
    },
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading
  };
};
