import React, { useEffect, useMemo, useState, useCallback } from 'react';
import _ from 'lodash';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Button, Col, Dropdown, Form, FormProps, Input, Row, Select, notification } from 'antd';
import {
  DollarCircleOutlined,
  IdcardOutlined,
  PlusCircleOutlined,
  SwapRightOutlined,
  UserOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  CloseCircleFilled,
  EditFilled
} from '@ant-design/icons';

import { useGetAllLabels } from 'hooks/useGetAllLabels';
import { BookingAction } from 'components/modal/components/booking-action';
import useModalStore from 'stores/useModal';
import { useGetCompanies } from 'hooks/useGetCompanies';
import { useGetSources } from 'hooks/useGetSources';
import useBookingStore from 'stores/useBooking';
import { buildClassName, formatCurrency, toLocalTime, trimTruthyValues } from 'utils';
import {
  BookingLine,
  BookingStatus,
  GetServiceType,
  ParamsExtraInfo
} from 'services/api/type/booking.type';
import { Labels } from 'services/api/type/labels.type';

import { MENU_ASSIGN_ROOM_VALUE, MenuAssignRoom } from 'constants/common';
import { MAP_ICON_ROOM_STATUS } from 'constants/icon';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';
import { refreshBookingList, refreshRoomMap } from 'hooks';
import { ReactComponent as IconBreakfast } from 'assets/images/breakfast.svg';
import { useGetTravelAgencies } from 'hooks/useGetTravelAgencies';
import useCommonStore from 'stores/useCommon';

interface RoomInfoTabProps {
  bookingLine: BookingLine;
}

const RoomInfoTab = ({ bookingLine }: RoomInfoTabProps) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const {
    setIsOpenAssignRoom,
    setIsOpenChangeRoomType,
    setIsOpenCancelAssignRoom,
    setIsOpenCancelRoom,
    setIsOpenCustomer,
    setIsOpenAddService,
    setIsOpenChangeDate,
    setIsOpenChangePrice,
    setInfoConfirmModal,
    setConfirmLoading,
    setIsOpenSetBreakfast,
    setIsOpenUndoCheckIn,
    setIsOpenUndoCheckOut,
    isOpenBookingDetail
  } = useModalStore();
  const { currentBranch } = useCommonStore();

  const { setSelectedCustomerId, setBookingLineId } = useBookingStore();

  const { data: companies } = useGetCompanies();
  const { data: sources } = useGetSources();

  const [showInput, setShowInput] = useState<boolean>(false);

  const handleSourceChange = (value: number) => {
    const selectedSourceObj = sources?.find(source => source.id === value);
    if (
      selectedSourceObj?.name.toLowerCase().includes('ota') &&
      !bookingLine.cms_booking_id &&
      !bookingLine.cms_ota_id
    ) {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };

  const handleEditOtaId = () => {
    setShowInput(true);
  };

  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const [selectedTravelAgency, setSelectedTravelAgency] = useState<any>({});
  const { data: labels } = useGetAllLabels(selectedLabel);
  const { data: travelAgencies } = useGetTravelAgencies(selectedTravelAgency);

  const { mutateAsync: mutateCreateAndAssignLabels } = useMutation({
    mutationFn: ({ bookingLineId, label_name }: { bookingLineId: number; label_name: string }) =>
      API.labels.createAndAssignLabels(bookingLineId, label_name)
  });
  const { mutateAsync: mutateUnassignLabels } = useMutation({
    mutationFn: ({ bookingLineId, label_name }: { bookingLineId: number; label_name: string }) =>
      API.labels.unassignLabels(bookingLineId, label_name)
  });

  const { mutateAsync: mutateUpdateExtraInfo, isPending } = useMutation({
    mutationFn: ({ bookingLineId, params }: { bookingLineId: number; params: ParamsExtraInfo }) =>
      API.booking.updateExtraInfo(bookingLineId, params)
  });
  const { mutateAsync: mutateRemoveService } = useMutation({
    mutationFn: ({
      bookingLineId,
      params
    }: {
      bookingLineId: number;
      params: {
        service_id: number;
      };
    }) => API.booking.removeService(bookingLineId, params)
  });

  useEffect(() => {
    if (isOpenBookingDetail) {
      form.resetFields();
      form.setFieldsValue(
        trimTruthyValues({
          note: bookingLine?.note,
          source_id: bookingLine?.source_id,
          medium_id: bookingLine?.medium_id,
          hotel_travel_agency_id: bookingLine?.hotel_travel_agency_id
        })
      );
    } else {
      setShowInput(false);
      setSelectedTravelAgency({});
    }
    if (sources && bookingLine.source_id) {
      const selectedSourceObj = sources?.find(source => source.id === bookingLine.source_id);
      if (
        selectedSourceObj?.name.toLowerCase().includes('ota') &&
        !bookingLine.cms_booking_id &&
        !bookingLine.cms_ota_id
      ) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingLine.booking_line_id,
    isOpenBookingDetail,
    setShowInput,
    sources,
    bookingLine.source_id,
    bookingLine.cms_booking_id
  ]);

  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case MENU_ASSIGN_ROOM_VALUE.CHANGE_ROOM_TYPE:
        setIsOpenChangeRoomType(true);
        break;
      case MENU_ASSIGN_ROOM_VALUE.ASSIGN_ROOM:
        setIsOpenAssignRoom(true);
        break;
      case MENU_ASSIGN_ROOM_VALUE.CANCEL_ASSIGN_ROOM:
        setBookingLineId(Number(bookingLine.booking_line_id));
        setIsOpenCancelAssignRoom(true);
        break;
      case MENU_ASSIGN_ROOM_VALUE.CANCEL_ROOM:
        setBookingLineId(Number(bookingLine.booking_line_id));
        setIsOpenCancelRoom(true);
        break;
      case MENU_ASSIGN_ROOM_VALUE.UNDO_CHECK_IN:
        setBookingLineId(Number(bookingLine.booking_line_id));
        setIsOpenUndoCheckIn(true);
        break;
      case MENU_ASSIGN_ROOM_VALUE.UNDO_CHECK_OUT:
        setBookingLineId(Number(bookingLine.booking_line_id));
        setIsOpenUndoCheckOut(true);
        break;
      default:
        break;
    }
  };

  const handleShowCustomerModal = () => {
    setIsOpenCustomer(true);
  };

  const handleShowAddService = () => {
    setBookingLineId(bookingLine.booking_line_id);
    setIsOpenAddService(true);
  };

  const handleShowChangeDateModal = () => {
    setBookingLineId(bookingLine.booking_line_id);
    setIsOpenChangeDate(true);
  };

  const handleShowChangePriceModal = () => {
    setIsOpenChangePrice(true);
  };

  // eslint-disable-next-line
  const handleSearch = useCallback(
    _.debounce(async search => {
      setSelectedLabel(search);
    }, 500),
    []
  );
  const onLabelChange = (value: string) => {
    setSelectedLabel(value);
  };

  // eslint-disable-next-line
  const handleSearchTravelAgency = useCallback(
    _.debounce(async value => {
      setSelectedTravelAgency({ name: value });
    }, 500),
    []
  );
  const handleTravelAgencyChange = (value: number, record: any) => {
    if (!record) {
      form.setFieldsValue({
        hotel_travel_agency_id: undefined
      });
      return;
    }
    form.setFieldsValue({
      hotel_travel_agency_id: value,
      source_id: record.source_id || undefined,
      medium_id: record.medium_id || undefined
    });
  };

  const handleRemoveService = async (service_id: number) => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận xóa dịch vụ',
      okText: 'Xác nhận',
      onOk: async () => {
        if (bookingLine?.booking_id) {
          try {
            setConfirmLoading(true);
            await mutateRemoveService({
              bookingLineId: bookingLine.booking_line_id,
              params: {
                service_id
              }
            });

            queryClient.invalidateQueries({
              queryKey: [
                QUERY_KEYS.KEY_ROOM,
                QUERY_KEYS.GET_BOOKING_DETAIL,
                bookingLine.booking_line_id
              ]
            });
            setInfoConfirmModal(false);
            notification.success({
              message: 'Xóa dịch vụ thành công'
            });
          } catch (err: any) {
            notification.error({
              message: 'Xóa dịch vụ thất bại'
            });
          } finally {
            setConfirmLoading(false);
          }
        }
      }
    });
  };

  const handleSetBreakfast = () => {
    setIsOpenSetBreakfast(true);
  };

  const handleUnassignLabel = async (value: string) => {
    await mutateUnassignLabels({
      bookingLineId: bookingLine.booking_line_id,
      label_name: value
    });
  };

  const onFinish: FormProps['onFinish'] = async (values: ParamsExtraInfo) => {
    try {
      for (const label_name of values?.label_name || '') {
        await mutateCreateAndAssignLabels({
          bookingLineId: bookingLine.booking_line_id,
          label_name: label_name
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_ALL_LABELS]
        });
      }
      await mutateUpdateExtraInfo({
        bookingLineId: bookingLine.booking_line_id,
        params: {
          note: values.note,
          hotel_travel_agency_id: values.hotel_travel_agency_id,
          medium_id: values.medium_id,
          source_id: values.source_id,
          cms_ota_id: values.cms_ota_id
        }
      });
      setShowInput(false);
      setSelectedTravelAgency({});
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLine.booking_line_id]
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, bookingLine.booking_line_id]
      });

      refreshBookingList(location);
      refreshRoomMap(location);

      notification.success({
        message: 'Lưu thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const menuAssignRoom = useMemo(() => {
    if (bookingLine.status === BookingStatus.CONFIRM && !bookingLine.room_id) {
      return MenuAssignRoom.filter(
        item =>
          item.key === MENU_ASSIGN_ROOM_VALUE.CHANGE_ROOM_TYPE ||
          item.key === MENU_ASSIGN_ROOM_VALUE.ASSIGN_ROOM ||
          item.key === MENU_ASSIGN_ROOM_VALUE.CANCEL_ROOM
      );
    }
    if (bookingLine.status === BookingStatus.CONFIRM && bookingLine.room_id) {
      return MenuAssignRoom.filter(
        item =>
          item.key === MENU_ASSIGN_ROOM_VALUE.CANCEL_ASSIGN_ROOM ||
          item.key === MENU_ASSIGN_ROOM_VALUE.CANCEL_ROOM
      );
    }
    if (bookingLine.status === BookingStatus.CHECK_IN) {
      return MenuAssignRoom.filter(item => item.key === MENU_ASSIGN_ROOM_VALUE.UNDO_CHECK_IN);
    }
    if (bookingLine.status === BookingStatus.CHECK_OUT) {
      return MenuAssignRoom.filter(item => item.key === MENU_ASSIGN_ROOM_VALUE.UNDO_CHECK_OUT);
    }
    return MenuAssignRoom;
  }, [bookingLine]);

  const totalMoney = useMemo(() => {
    if (!bookingLine.services?.length) {
      return 0;
    }
    return bookingLine.services.reduce((cur: number, next: GetServiceType) => {
      if (next.is_deleted) {
        return cur;
      }
      const total = next.price * (next?.qty || 0);
      return cur + total;
    }, 0);
  }, [bookingLine.services]);

  const displayBreakfast = useMemo(() => {
    if (!_.isEmpty(bookingLine.breakfast_dates)) {
      const startDate = _.get(bookingLine, 'breakfast_dates.0.date', '');
      const endDate = _.get(
        bookingLine,
        `breakfast_dates.${bookingLine.breakfast_dates.length - 1}.date`,
        ''
      );
      return `${toLocalTime(startDate, 'DD/MM')} - ${toLocalTime(endDate, 'DD/MM')}`;
    }
    return '';
  }, [bookingLine]);

  const isCancelOrCheckOut = [BookingStatus.CHECK_OUT, BookingStatus.CANCEL].includes(
    bookingLine.status
  );

  const IconStatus = MAP_ICON_ROOM_STATUS[bookingLine.status];

  return (
    <Row className="modal-content">
      <Col span={24} md={8}>
        <div className="customer-highlight">
          <UserOutlined />
          <span className="booking-id">
            #{bookingLine.booking_id} - {bookingLine.booking_line_id}
          </span>
          <span className="remain">
            Còn lại{' '}
            {isCancelOrCheckOut
              ? 0
              : currentBranch?.is_enable_v2
                ? formatCurrency(bookingLine.remain_amount)
                : formatCurrency(bookingLine.total_price - (bookingLine.paid_amount || 0))}
          </span>
        </div>
        <div className="customer-room">
          <div className="flex items-center">
            {IconStatus && (
              <IconStatus
                className={classNames('icon-status', {
                  'icon-status--confirm': bookingLine.status === BookingStatus.CONFIRM,
                  'icon-status--checkin': bookingLine.status === BookingStatus.CHECK_IN,
                  'icon-status--checkout': bookingLine.status === BookingStatus.CHECK_OUT
                })}
              />
            )}
            <span className="room-id">
              {bookingLine.original_room_type_name !== bookingLine.room_type_name && (
                <span className="original-room-type">
                  {bookingLine.original_room_type_name} &gt;
                </span>
              )}{' '}
              {bookingLine.room_name || bookingLine.room_type_name + ' N/A'}
            </span>

            {[BookingStatus.CONFIRM, BookingStatus.CHECK_IN, BookingStatus.CHECK_OUT].includes(
              bookingLine.status
            ) && (
              <Dropdown
                menu={{ items: menuAssignRoom, onClick: handleClickMenu }}
                trigger={['click']}
              >
                <Button icon={<EllipsisOutlined style={{ fontSize: '20px' }} />} type="text" />
              </Dropdown>
            )}
          </div>
          <BookingAction bookingLine={bookingLine} />
        </div>
        <div className="customer-range-date">
          <Button
            disabled={isCancelOrCheckOut}
            type="text"
            className="w-full"
            onClick={handleShowChangeDateModal}
          >
            <div className="flex items-center">
              <CalendarOutlined />
              <div>
                <span className="time">{toLocalTime(bookingLine.check_in, 'DD/MM - HH:mm')}</span>
                <SwapRightOutlined />
                <span className="time">{toLocalTime(bookingLine.check_out, 'DD/MM - HH:mm')}</span>
              </div>
            </div>
          </Button>
        </div>

        <div className="customer-price">
          <Button
            type="text"
            className="w-full"
            onClick={handleShowChangePriceModal}
            disabled={isCancelOrCheckOut}
          >
            <DollarCircleOutlined />
            <span>
              {bookingLine.pricelist?.name || 'Giá mặc định'} (Tổng:{' '}
              {formatCurrency(bookingLine.subtotal_price)} )
            </span>
          </Button>
        </div>
        <div className="customer-type">
          <Button
            type="text"
            className="w-full"
            onClick={handleShowCustomerModal}
            disabled={isCancelOrCheckOut}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <UserOutlined />
                <span>
                  {bookingLine?.adult} người lớn, {bookingLine.child} trẻ em
                </span>
              </div>
              <PlusCircleOutlined style={{ color: '#9C4F3B' }} />
            </div>
          </Button>
        </div>
        <div className="customer-list">
          {bookingLine?.customers?.map((item, index) => (
            <Button
              key={item.id}
              type="text"
              className="w-full"
              onClick={() => {
                setSelectedCustomerId(index);
                handleShowCustomerModal();
              }}
            >
              <div className="customer-name" key={item.id}>
                <span>{item.name}</span>
                <div className="flex items-center">
                  <IdcardOutlined />
                </div>
              </div>
            </Button>
          ))}
        </div>
        {bookingLine.status === BookingStatus.CANCEL && (
          <div className="cancel-note">
            <span className="required">Đã hủy: {bookingLine?.cancel_reason}</span>
          </div>
        )}
      </Col>

      <Col span={24} md={8}>
        <Form
          form={form}
          name="booking-line-form"
          layout="vertical"
          style={{ width: '100%' }}
          initialValues={{
            note: bookingLine.note || '',
            label_name: bookingLine.labels?.map(label => label.name) || [],
            cms_ota_id: bookingLine.cms_ota_id || ''
          }}
          onFinish={onFinish}
          autoComplete="off"
          disabled={isCancelOrCheckOut}
        >
          <Form.Item name="note">
            <Input.TextArea placeholder="Ghi chú" autoSize={{ minRows: 3, maxRows: 7 }} />
          </Form.Item>
          <Form.Item name="hotel_travel_agency_id">
            <Select
              options={
                travelAgencies &&
                travelAgencies.map(travelAgency => ({
                  value: travelAgency.id,
                  label: travelAgency.name,
                  source_id: travelAgency.source_id,
                  medium_id: travelAgency.medium_id
                }))
              }
              showSearch
              filterOption={(input, option) =>
                !!option && option.label.toLowerCase().includes(input.toLowerCase())
              }
              onSearch={handleSearchTravelAgency}
              onClear={() => setSelectedTravelAgency({})}
              onChange={(value, record) => handleTravelAgencyChange(value, record)}
              placeholder="Đại lý du lịch"
              allowClear
            />
          </Form.Item>
          <Form.Item name="medium_id">
            <Select
              options={companies}
              showSearch
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder="Phân khúc thị trường"
            />
          </Form.Item>
          <Form.Item name="source_id">
            <Select
              options={sources}
              showSearch
              onChange={handleSourceChange}
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder="Chọn nguồn"
            />
          </Form.Item>

          <Form.Item name="label_name">
            <Select
              options={labels.map((label: Labels) => ({
                value: label.name,
                label: label.name,
                color: label.color
              }))}
              mode="tags"
              placeholder="Nhóm"
              onChange={onLabelChange}
              onSearch={handleSearch}
              onClear={() => setSelectedLabel('')}
              onDeselect={value => handleUnassignLabel(value)}
              showSearch
              filterOption={false}
              optionRender={option => (
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 10px'
                  }}
                >
                  <span
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: option.data.color || '#f0f0f0',
                      marginRight: '8px'
                    }}
                  />
                  {option.label}
                </span>
              )}
            />
          </Form.Item>
          {showInput && (
            <Form.Item name="cms_ota_id">
              <Input placeholder="Nhập OTA/External ID" />
            </Form.Item>
          )}
          {/* <Form.Item name="market" style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item> */}
          {bookingLine.cms_ota_id && (
            <p className="booking-id">
              OTA ID: {bookingLine.cms_ota_id}
              {bookingLine.cms_booking_source && ` (${bookingLine.cms_booking_source})`}{' '}
              {!bookingLine.cms_booking_id && (
                <Button onClick={handleEditOtaId} icon={<EditFilled />}></Button>
              )}
            </p>
          )}

          <div className="room-form__action">
            <Button htmlType="submit" className="ant-btn-secondary" loading={isPending}>
              Lưu
            </Button>
          </div>
        </Form>
      </Col>

      <Col span={24} md={8}>
        <div className="service">
          <Button
            icon={<IconBreakfast />}
            type="text"
            className="service-breakfast"
            onClick={handleSetBreakfast}
          >
            <span>
              {displayBreakfast
                ? `Ăn sáng: ${displayBreakfast} (${bookingLine.breakfast_dates.length} ngày)`
                : 'Phòng không ăn sáng'}
            </span>
          </Button>

          <div className="service-total">
            <span>
              <strong>Dịch vụ</strong> Tổng (VND): {totalMoney.toLocaleString('vn')}
            </span>
            <Button
              type="link"
              disabled={isCancelOrCheckOut}
              icon={<PlusCircleOutlined />}
              onClick={handleShowAddService}
            ></Button>
          </div>
          <div className="service-wrapper">
            {!!bookingLine.services?.length &&
              bookingLine.services.map(item => {
                return (
                  <div className="service-detail" key={item.id}>
                    <div
                      className={buildClassName({
                        'service-detail__info': true,
                        'line-through': item.is_deleted
                      })}
                    >
                      <span className="service-detail__info-item">
                        {item.name} ({item.qty})
                      </span>
                      <span className="service-detail__info-price">
                        {formatCurrency(item.subtotal_price)}
                      </span>
                    </div>
                    <div className="service-detail__name">
                      <span>
                        {toLocalTime(item.created_date, 'DD/MM HH:mm')} - {item.username}
                      </span>
                    </div>

                    <Button
                      className="service-detail__remove"
                      type="link"
                      icon={<CloseCircleFilled />}
                      onClick={() => handleRemoveService(item.id)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default RoomInfoTab;
