import { formatCurrency, toLocalTime } from 'utils';
import ButtonGroup from 'antd/es/button/button-group';
import { Button, Card, Col, Empty, notification, Row } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import useModal from 'stores/useModal';
import useModalStore from 'stores/useModal';
import { actionCheckOut } from 'services/api/module/booking.api';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { matchPath } from 'react-router-dom';
import PAGES from 'routes/constants';
import { BookingPrice, BookingStatus } from 'services/api/type/booking.type';

interface CashierReservationProps {
  bookingLine: any;
  orders?: any;
}

export const CashierReservation = (props: CashierReservationProps) => {
  const { bookingLine, orders } = props;
  const { setOpenBookingDetail } = useModal();
  const { setInfoConfirmModal } = useModalStore();

  const { mutateAsync: mutateCheckOut } = useMutation({
    mutationFn: () => actionCheckOut(bookingLine.booking_line_id)
  });

  const openBookingDetail = () => {
    if (bookingLine) {
      setOpenBookingDetail(true, bookingLine.booking_line_id);
    }
  };

  const handleCheckOut = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận trả phòng',
      onOk: async () => {
        setInfoConfirmModal(false);
        try {
          await mutateCheckOut();
          notification.success({
            message: 'Trả phòng thành công'
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.KEY_ROOM]
          });
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS]
          });
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_GUEST]
          });
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
          });

          if (matchPath(PAGES.roomMap, location.pathname)) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
            });

            if (matchPath(PAGES.cleaningCalendar, location.pathname)) {
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
              });
            }
          }
        } catch (err: any) {
          notification.error({
            message: err.error || 'Lỗi xảy ra'
          });
        }
      }
    });
  };

  return (
    <Card className="pms-cashier__reservation" style={{ maxWidth: '700px' }}>
      <div className="flex items-center justify-between">
        <div className="title">
          {bookingLine
            ? `#${bookingLine.booking_id} - ${bookingLine.booking_line_id} - ${bookingLine.room_name ? bookingLine.room_name : bookingLine.room_type_name || ''}`
            : ''}
        </div>
        {bookingLine && (
          <div>
            <ButtonGroup style={{ columnGap: 8 }}>
              <Button
                className="ant-btn-secondary"
                onClick={() => handleCheckOut()}
                disabled={orders?.length === 0 || bookingLine.status !== BookingStatus.CHECK_IN}
              >
                Checkout
              </Button>
              <Button className="ant-btn-secondary" onClick={() => openBookingDetail()}>
                Edit Reservation
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
      {bookingLine ? (
        <>
          <div className="flex items-center justify-between reservation-row">
            <p className="time" style={{ whiteSpace: 'nowrap' }}>
              <p>Room Charge:</p>
              {bookingLine.booking_prices?.map((booking_price: BookingPrice, index: any) => (
                <Row key={index}>
                  <Col span={24} md={18}>
                    ({toLocalTime(booking_price.start_date, 'DD/MM HH:mm')} -{' '}
                    {toLocalTime(booking_price.end_date, 'DD/MM HH:mm')}){' '}
                    {formatCurrency(booking_price.price)}
                    {'    '}
                    {booking_price.is_posted && (
                      <span
                        style={{
                          display: 'inline-block',
                          padding: '1px',
                          lineHeight: '1',
                          verticalAlign: 'baseline',
                          color: 'green'
                        }}
                      >
                        <CheckCircleFilled />
                      </span>
                    )}
                  </Col>
                </Row>
              ))}
            </p>
            <div>
              <p className="rate">
                Balance: <span className="rate--error">{formatCurrency(bookingLine.balance)}</span>
              </p>
              <p className="rate">
                Remain Amount:{' '}
                <span className="rate--error">{formatCurrency(bookingLine.remain_amount)}</span>
              </p>
            </div>
          </div>
          <div className="reservation-note">
            <p style={{ marginTop: '5px', marginLeft: '10px' }}>{bookingLine.note}</p>
          </div>
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
