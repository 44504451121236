import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { useGetMe } from 'hooks/useGetMe';

export const useGetAllBranches = () => {
  const { data: user } = useGetMe();
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_BRANCHES],
    queryFn: API.branches.getAllBranches,
    enabled: !!user,
    refetchOnWindowFocus: false
  });

  return {
    data: data?.filter(branch => user?.branch_ids.includes(branch.id)),
    isLoading
  };
};
