import { Form, FormProps, notification } from 'antd';
import useModal from 'stores/useModal';

import { useMutation } from '@tanstack/react-query';
import { postPayment } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import 'styles/post-payment-transaction.scss';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import { useGetMe } from 'hooks/useGetMe';
import { useGetGuestOrderDetail, useGetTransactionCodes } from 'hooks/useGetGuestTransactions';
import { toLocalTime } from 'utils';
import { RenderPostTransactionModal } from 'components/modal/transaction-modal/utils/renderPostTransactionModal';

function PostPaymentTransaction() {
  const [form] = Form.useForm();

  const {
    postPaymentTransaction: {
      folio_balance_code,
      booking_line_id,
      guest_id,
      orderId,
      isOpenPostPaymentTransaction
    },
    closePostPaymentTransaction
  } = useModal();

  const { data: bookingLineDetail } = useGetBookingDetail(booking_line_id);
  const { data: userData } = useGetMe();
  const { data: transactionCodes } = useGetTransactionCodes();
  const { data: orderDetails } = useGetGuestOrderDetail(orderId);

  const uniqueTransactionCodesName = new Set([
    transactionCodes
      ?.filter(item => Number(item.sub_code) > 0 && item.transaction_group === 'payment')
      .map(item => item.name)
  ]);

  const { mutateAsync: mutatePostPaymentTransaction, isPending } = useMutation({
    mutationFn: (params: any) =>
      postPayment({
        guest_id: params.guest_id,
        folio_balance_code: params.folio_balance_code,
        note: params.note,
        transaction_code_id: params.transaction_code_id,
        amount: params.amount,
        transaction_date: params.transaction_date
      })
  });

  const handleCloseModal = () => {
    closePostPaymentTransaction();
    form.resetFields();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutatePostPaymentTransaction({
        guest_id: guest_id,
        folio_balance_code: values.folio_balance_code,
        note: values.note,
        transaction_code_id: values.transaction_code_id,
        amount: values.amount,
        transaction_date: toLocalTime(values.transaction_date, 'YYYY-MM-DD')
      });
      closePostPaymentTransaction();
      form.resetFields();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, guest_id]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, booking_line_id]
      });
      notification.success({
        message: 'Tạo giao dịch thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <RenderPostTransactionModal
      title="POST PAYMENT TRANSACTION"
      isOpen={isOpenPostPaymentTransaction}
      handleClose={handleCloseModal}
      form={form}
      onFinish={onFinish}
      isPending={isPending}
      folio_balance_code={folio_balance_code}
      guest_id={guest_id}
      bookingLineDetail={bookingLineDetail}
      userData={userData}
      transactionCodes={transactionCodes}
      orderDetails={orderDetails}
      uniqueTransactionCodesName={uniqueTransactionCodesName}
    />
  );
}

export default PostPaymentTransaction;
